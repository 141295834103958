.product-card {

    .details-title-row {
        h1 {
            font-size: 36px;
            line-height: 130%;
        }
    }

    .gallery-block {
        padding-right: 20px;
    }

    .details-param-name {
        padding-right: 5px;
    }

    .color-viewer-item-block {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 7px;
        padding-right: 7px;
    }

    .product-card-top {
        display: flex;
        justify-content: space-between;
        grid-gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 30px;

        .product-card-top-info {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            flex-wrap: wrap;
            grid-gap: 16px;



            .rating {
                .rating-item {
                    &:before {
                        font-size: 24px;
                    }
                }
            }


            .details-sku {
                display: flex;
                /*margin-bottom: 10px;*/
                .details-param-name {
                    font-size: 12px;
                }

                .details-param-value {
                    font-size: 12px;
                }
            }

            .details-reviews {
                /*margin-bottom: 10px;*/
                display: flex;

                a {
                    font-size: 12px;
                }
            }

            .details-param-value-weight {
                display: inline-block;
            }

            .details-weight, .details-unit, .details-dimensions, .details-meta {
                /*margin-bottom: 10px;*/
                display: flex;
            }

            .details-meta {
                .products-view-meta {
                    margin-bottom: 0;

                    .products-view-meta-list {
                        .products-view-meta-item {
                            border: none;

                            span {
                                font-size: .75rem;
                            }
                        }
                    }
                }
            }
        }

        .product-card-read {
            width: 354px;
            text-align: right;
            margin-bottom: 10px;
            flex: 354px 0 0;
        }
    }

    .details-colors {
        .color-viewer-list[data-view-mode="icon"], .color-viewer-list[data-view-mode="iconandtext"] {
            padding: 0;

            .color-viewer-item-wrap {
                padding: 0;
                margin-bottom: 10px;

                .color-viewer-item-block {
                    margin-right: 8px;
                    display: block;
                    width: auto;
                    border-radius: 100%;
                    padding: 1px;

                    .color-viewer-inner {
                        display: flex;
                        border-radius: 100%;
                        margin: 0;
                        padding: 0px;
                    }
                }

                .color-viewer-item-block.color-viewer-item-selected {
                    border-radius: 100%;
                    padding: 1px;
                }
            }
        }
    }

    .details-row {
        margin-top: 0;
    }

    .product-card-info-inner {
        .linked-products__bundle-name {
            font-size: 14px;
        }
    }

    .details-colors {
        margin-bottom: 30px;

        .color-viewer-header {
            font-size: 14px;
            margin-bottom: 14px;
        }
    }

    .details-sizes {
        margin-bottom: 30px;

        .sizes-viewer-header {
            font-size: 14px;
            margin-bottom: 10px;
        }

        .sizes-viewer-block {
            margin: 0 4px 14px 0;

            .sizes-viewer-item {
                box-sizing: border-box;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;


                .sizes-viewer-inner {
                    margin: 0;
                    line-height: normal;
                    padding: 10px;
                    font-size: 14px;
                    min-width: 35px;
                    box-sizing: border-box;

                    span {
                        white-space: nowrap;
                        text-align: center;
                    }
                }
            }

            .sizes-viewer-item-disabled {
                opacity: 0.5;
            }
        }
    }

    .details-amount {
        margin-bottom: 30px;

        .details-param-name {
            font-size: 14px;
            margin-bottom: 14px;
        }

        .details-param-value {
            .details-spinbox-block {
                position: relative;
                width: 130px;

                .spinbox-input-wrap {
                    input {
                        box-sizing: border-box;
                        border-radius: 4px;
                        width: 130px;
                        padding: 13.5px 16px;
                        padding-right: 40px;
                        font-size: 14px;
                    }
                }

                .spinbox__arrows {
                    position: absolute;
                    right: 12px;

                    .spinbox-more {
                        &:before {
                            content: '';
                            -webkit-mask-image: url(../../images/arrow-spinbox.svg);
                            mask-image: url(../../images/arrow-spinbox.svg);
                            background-repeat: no-repeat;
                            width: 12px;
                            height: 9px;
                            background-repeat: no-repeat;
                            background-position: center;
                            margin-right: 0;
                            transform: rotate(180deg);
                            vertical-align: top;
                        }
                    }

                    .spinbox-less {
                        &:before {
                            content: '';
                            -webkit-mask-image: url(../../images/arrow-spinbox.svg);
                            mask-image: url(../../images/arrow-spinbox.svg);
                            background-repeat: no-repeat;
                            width: 12px;
                            height: 9px;
                            background-repeat: no-repeat;
                            background-position: center;
                            margin-right: 0;
                            vertical-align: bottom;
                        }
                    }
                }
            }
        }
    }

    .details-payment {
        border: none;
        margin-top: 40px;
        padding: 0;

        .details-payment-inline {
            display: inline-block;
        }

        .details-payment-block {
            display: block;

            .details-payment-cell {
                display: inline-block;

                .details-buy-one-click {
                    margin-bottom: 20px;
                }

                .price-unknown {
                    font-size: 14px;
                    font-weight: 500;
                }

                .price {
                    margin-bottom: 6px;

                    .price-current {
                        font-size: 36px;
                    }

                    .price-new {
                        font-size: 36px;
                        margin-bottom: 7px;
                    }
                }

                .details-payment-item {
                    padding-left: 0;
                    margin-bottom: 12px;

                    .btn {
                        &:before {
                            content: '';
                            background-repeat: no-repeat;
                            background-size: 100%;
                            width: 20px;
                            font-size: 6px;
                            height: 20px;
                            background-repeat: no-repeat;
                            background-position: center;
                            margin-right: 8px;
                            -webkit-mask-image: url(../../images/productCard-cart.svg);
                            mask-image: url(../../images/productCard-cart.svg);
                        }
                    }

                    .details-buy-one-click {
                        .link-dotted-invert {
                            font-size: 12px;
                            /*border-bottom-style: none;*/
                        }
                    }
                }

                .details-payment-item.details-payment-inline {
                    margin-top: 10px;
                }
            }
        }
    }

    .details-availability {
        padding-top: 5px;
        padding-bottom: 5px;

        .not-available,
        .available {
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 0;
            background: none;
            padding: 0;
            padding-left: 0;
        }

        .not-available {
            font-size: 12px;
            margin-bottom: 8px;
            margin-top: 8px;
            background: none;
        }
    }

    .details-wishlist {
        padding: 0;
        border: none;
        font-size: 12px;

        .wishlist-control {
            display: flex;
            align-items: center;
        }

        .custom-input-text {
            font-size: 12px;
        }
    }

    .details-aside {
        .block-exuding-wrap {
            margin-bottom: 17px;
        }

        .block-exuding {
            border-radius: 4px;
            margin-bottom: 0;

            .details-shipping {
                /*padding: 0 21px 8px 21px;*/
                padding: 0;

                &__variants {
                    max-height: 400px;
                    overflow: auto;
                    padding-left: 21px;
                    padding-right: 21px;
                }

                [data-ng-if="shippingVariants.showZip"] {
                    .form-field-name {
                        padding-bottom: 7px;
                        display: flex;
                        font-size: 14px;
                        padding-top: 7px;
                    }
                }

                .top-shipping-row {
                    padding-bottom: 13px;
                    padding-left: 21px;
                    padding-right: 21px;
                    font-size: 16px;
                    margin-bottom: 0;

                    .link-dotted-invert {
                        border-bottom-style: none;
                        position: relative;

                        span {
                            padding-left: 18px;

                            &:before {
                                content: '';
                                /*background: url(../../images/Union.svg);*/
                                -webkit-mask-image: url(../../images/Union.svg);
                                mask-image: url(../../images/Union.svg);
                                background-repeat: no-repeat;
                                width: 8px;
                                height: 14px;
                                display: block;
                                background-position: center;
                                position: relative;
                                position: absolute;
                                left: 3px;
                                top: 1px;
                            }
                        }
                    }
                }

                [data-shipping-variants] {
                    .shipping-variants-row {
                        padding-bottom: 7px;
                        display: flex;
                        font-size: 14px;
                        padding-top: 7px;
                        justify-content: space-between;
                        align-items: center;

                        &:last-child {
                            border-bottom: none;
                            padding-bottom: 0;
                        }

                        .shipping-variants-value {
                            width: auto;

                            span {
                                /*white-space: nowrap;*/
                            }
                        }
                    }
                }
            }
        }
    }

    .social-btns {
        padding: 26px 21px 26px 21px;
    }

    .tabs {
        margin-bottom: 80px;

        .tabs-headers {
            display: flex;
            position: relative;

            &:after {
                position: absolute;
                bottom: 0;
                z-index: -1;
                width: 100vw;
                margin-left: calc(((-100vw + 100%)/2)); //10 width scrollbar вынести в css переменную?
            }

            .tabs-header-item {
                border: none;
                padding: 0;
                margin-right: 32px;
                border-radius: 0;

                .tabs-header-item-link {
                    border-bottom-style: none;
                    font-size: 18px;
                    padding-bottom: 32px;
                }
            }

            .tabs-header-item.tabs-header-active {
                border-bottom: 1px solid #8A8A8F !important;

                .tabs-header-item-link {
                    color: #8A8A8F;
                }
            }
        }

        .tabs-content {
            padding: 0;
            padding-top: 40px;
        }
    }


    .block {
        .block-content {
            .products-view {
                /*.product-view-carousel-list {
                    .products-view-block {
                        -ms-flex-preferred-size: 291.667px !important;
                        flex-basis: 291.667px !important;
                        max-width: 291.667px !important;
                        width: 291.667px !important;
                    }
                }*/
                /*.carousel {

                    .carousel-nav.carousel-nav-outside {
                        position: absolute;
                        right: 0;
                        top: 35px;
                        bottom: 0;
                        display: flex;
                        height: 180px;
                        align-items: center;

                        .carousel-nav-prev {
                            font-size: 0;
                            opacity: 0.8;
                            width: 48px;
                            height: 48px;
                            display: block;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                        }

                        .carousel-nav-next {
                            font-size: 0;
                            opacity: 0.8;
                            width: 48px;
                            height: 48px;
                            display: block;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .carousel-nav-prev:disabled, .carousel-nav-next:disabled {
                            opacity: .4;
                        }
                    }
                }*/
                .carousel.carousel-nav-not-show > .carousel-nav {
                    display: none;
                }
            }
        }
    }

    .details-block {
        .details-additional-graphics {
            margin: 0;
            margin-top: 10px;
        }

        .gallery-photos {
            margin-top: 0;

            .carousel {
                padding: 0;

                .carousel-inner {

                    .carousel-list {

                        .details-carousel-item {
                            /*display: inline-block;
                            border-radius: 0;
                            border: none;*/
                            .gallery-photos-item {
                                height: auto !important;
                                display: block;
                                /*border: none;*/
                                /*padding: 0;*/
                                /*border-radius: 0;
                                margin-right: 8px;*/
                                img {
                                    max-height: 85px !important;
                                }
                            }

                            .details-carousel-item-inner {
                                height: 85px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 8px;

                                .details-photos__trigger-video {
                                    height: 40px;
                                    width: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .review-form-block {
        .review-form-button {
            .btn-submit {
                border-radius: 4px;
                font-weight: normal;
                font-size: 13px;
            }
        }
    }

    .product-card-info {
        &__inner {
            height: 100%;
        }

        .products-view-compare {
            margin-bottom: 10px;

            .compare-control {
                display: flex;
                align-items: center;

                .svg {
                    width: 24px;
                    height: 24px;

                    svg {
                        path {
                            &:last-child {
                                stroke: none;
                            }
                        }
                    }
                }

                .custom-input-text {
                    margin-left: 6px;
                    font-size: 13px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .products-view-wishlist {
            margin-bottom: 13px;

            .wishlist-control {
                display: flex;
                align-items: center;
                margin-left: 3px;

                .svg {
                    width: 20px;
                    height: 14px;

                    svg {
                        width: 18px;
                        height: 16px;
                    }
                }

                .custom-input-text {
                    margin-left: 6px;
                    font-size: 13px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .details-custom-options {
            .custom-options-row {

                .custom-options-value {
                    label {
                        display: flex;
                        align-items: center;
                    }
                }

                .custom-options-name {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        &__details-aside {
            position: relative;
            /*.block-exuding-wrap {
                position: absolute;
                left: 10px;
                right: 10px;
                height: calc(100% - var(--details-aside__brand-image) - var(--details-aside__module-wrap) - 17px); //17px margin-bottom
                overflow: auto;
            }*/
            .top-shipping-row {
                position: sticky;
                padding-top: 26px;
                top: 0;
            }

            .social-btns {
                position: sticky;
                bottom: 0;
                background-color: inherit;
            }
        }
    }

    .details-briefproperties {
        margin-bottom: 15px;
    }

    .properties-item-row {
        align-items: center;
    }

    .quantity-discount {
        margin-left: 0;
        margin-top: 10px;
    }

    .product-card-info-inner,
    .details-payment {
        #ONclientWrap {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .details-aside {
        #ONclientWrap {
            margin-left: 7px !important;
            margin-right: 7px !important;
        }

        &__module-wrap {
            font-size: 14px;
        }
    }

    .productset {
        .products-view-price .price {
            text-align: left;
        }

        .products-view-name {
            margin-bottom: 5px;
        }
    }

    .products-view-price-yellow-price {
        &::before {
            margin-top: 0 !important;
            top: 0;
        }
    }

    .notify-wrap {
        margin-bottom: 10px;
    }

    .details-payment {
        .notify-wrap {
            margin-bottom: 20px;
        }
    }

    .custom-options-radio-wrap {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .last-order-notify-text {
        white-space: normal;
    }
}

.adv-modal-background .details-modal-preview .details-carousel-item-vertical,
.details-carousel-item {
    border: 2px solid transparent;
    border-radius: 5px;
}

.modal-video.modal-product-video {
    /*min-width: 660px;
    min-height: 464px;*/
    .modal-content {
        /*padding: 0;*/
        padding-bottom: 30px;
        padding-top: 50px;
    }
}

.instead {
    &__text-price {
        white-space: normal;
        font-weight: 500;
    }
}


.products-view-tile .price-text-instead {
    text-align:left;
}